/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var AboutUs = {
    gridBio: function() {
      $(window).on("grid:items:added", function(e, context) {
        $('.vc_gitem_row .vc_gitem-col > p', context).each(function(i, val) {
          $(this).after('<a href="#" class="btn btn-primary btn-sm read-more">Read Bio</a>');
        });
        
        $('.read-more', context).click(function() {
          var button = $(this);
          var paragraph = button.prev('p');
          if (button.hasClass('open')) {
            button.html('Read Bio');
            paragraph.slideUp();
            button.removeClass('open')
          }
          else {
            button.html('Close');
            paragraph.slideDown();
            button.addClass('open');
          }
          
          return false;
        });
        
        if (window.location.hash) {
          var pos = $(window.location.hash).position();
          
          if (pos) {
            $('html, body').scrollTop(pos.top);
          }
        }
      });
    }
  };
  
  var AMMatchHeight = {
    frontpage: function() {
      $('.wp-posts-carousel-details').matchHeight();
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        AMMatchHeight.frontpage();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
        AboutUs.gridBio();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
